<template>
  <c-box id="listNutritionists">
    <HeaderComp />
    <c-box
      padding-top="30px"
      padding-bottom="80px"
    >
      <c-box
        w="100%"
        max-width="1200px"
        mx="auto"
      >
        <c-breadcrumb
          v-chakra="{
            'ol': {
              'li': {
                'a': {
                  fontSize: '14px',
                  fontFamily: 'Roboto'
                },
                'span': {
                  fontSize: '14px',
                  fontFamily: 'Roboto',
                },
              },
            },

          }"
          margin-top="20px"
          margin-bottom="20px"
        >
          <c-breadcrumb-item>
            <c-breadcrumb-link
              href="#"
              color="primary.400"
              font-size="14px"
              font-fammily="Roboto"
            >
              Beranda
            </c-breadcrumb-link>
          </c-breadcrumb-item>
          <c-breadcrumb-item>
            <c-breadcrumb-link
              href="#"
              color="primary.400"
              font-size="14px"
              font-fammily="Roboto"
            >
              Manajemen Klien
            </c-breadcrumb-link>
          </c-breadcrumb-item>

          <c-breadcrumb-item
            is-current-page
          >
            <c-breadcrumb-link
              href="#"
              font-size="14px !important"
              font-fammily="Roboto !important"
            >
              Diet Data Klien
            </c-breadcrumb-link>
          </c-breadcrumb-item>
        </c-breadcrumb>

        <c-box
          w="100%"
          max-width="1200px"
          mx="auto"
          margin-top="50px"
        >
          <c-flex
            flex-wrap="wrap"
            w="100%"
          >
            <c-box
              w="40%"
              padding-right="20px"
            >
              <c-flex 
                w="100%"
                px="0"
                py="0"
              >
                <c-box
                  width="72px"
                  height="72px"
                  margin-right="30px"
                >
                  <c-box
                    w="72px"
                    h="72px"
                    overflow="hidden"
                    border-radius="100%"
                    background-color="lightGray.900"
                  />
                </c-box>
                <c-box w="100%">
                  <c-heading
                    as="h3"
                    pos="relative"
                    margin-bottom="10px"
                    font-size="18px"
                    line-height="27px"
                    font-weight="700"
                    color="black.900"
                  >
                    Nama Klien
                  </c-heading>
                  <c-text
                    font-family="Roboto"
                    font-size="14px"
                    color="primary.400"
                    margin-bottom="20px"
                  >
                    98782629109
                  </c-text>
                </c-box>
              </c-flex>
              <c-list
                v-chakra="{
                  'li': {
                    'a': {
                      color: 'darkGray.900',
                      fontSize: '16px',
                      lineHeight: '24px',
                      padding: '15px 20px',
                      display: 'block',
                      fontWeight: '700',
                      borderRadius: '8px',
                      '&:hover': {
                        textDecoration: 'none',
                        backgroundColor: 'superLightGray.900'
                      },
                      '&:focus': {
                        outline: 'none',
                        boxShadow: 'none'
                      }
                    },
                    '&.active': {
                      'a': {
                        backgroundColor: 'superLightGray.900',
                        borderLeft: '6px solid #008C81',
                        'img': {
                          'path': {
                            fill: '#008C81 !important'
                          }
                        }
                      }
                    }
                  }
                }"
              >
                <c-list-item
                  margin-top="30px"
                  class="active"
                >
                  <c-link href="#">
                    <c-image
                      :src="require('@/assets/ic_user.svg')"
                      alt="icon"
                      display="inline-block"
                      vertical-align="middle"
                      margin-right="8px"
                    />
                    Data Diet Klien
                  </c-link>
                </c-list-item>
                <c-list-item
                  margin-top="30px"
                >
                  <c-link href="#">
                    <c-image
                      :src="require('@/assets/ic_notebook.svg')"
                      alt="icon"
                      display="inline-block"
                      vertical-align="middle"
                      margin-right="8px"
                    />
                    ADIME Note
                  </c-link>
                </c-list-item>
                <c-list-item
                  margin-top="30px"
                >
                  <c-link href="#">
                    <c-image
                      :src="require('@/assets/ic_notes.svg')"
                      alt="icon"
                      display="inline-block"
                      vertical-align="middle"
                      margin-right="8px"
                    />
                    Follow Up Notes
                  </c-link>
                </c-list-item>
                <c-list-item
                  margin-top="30px"
                >
                  <c-link href="#">
                    <c-image
                      :src="require('@/assets/ic_trending.svg')"
                      alt="icon"
                      display="inline-block"
                      vertical-align="middle"
                      margin-right="8px"
                    />
                    Progress &amp; Tracker
                  </c-link>
                </c-list-item>
                <c-list-item
                  margin-top="30px"
                >
                  <c-link href="#">
                    <c-image
                      :src="require('@/assets/ic_meat.svg')"
                      alt="icon"
                      display="inline-block"
                      vertical-align="middle"
                      margin-right="8px"
                    />
                    Meal Planner
                  </c-link>
                </c-list-item>
              </c-list>
            </c-box>
            <c-box w="60%">
              <c-box margin-bottom="40px">
                <c-text
                  color="primary.400"
                  font-family="roboto"
                  text-transform="uppercase"
                  font-weight="700"
                  font-size="18px"
                  margin-bottom="10px"
                >
                  Formulir Gizi
                </c-text>
                <c-heading
                  font-weight="600"
                  font-size="24px"
                  line-height="36px"
                  color="black.900"
                >
                  Program Body Goals
                </c-heading>
              </c-box>
              <c-flex
                v-chakra="{
                  '.step': {
                    w: '100%',
                    h: '8px',
                    maxWidth: '156px',
                    borderRadius: '20px',
                    backgroundColor: 'superLightGray.900',
                    marginRight: '12px',
                    ':last-child': {
                      marginLeft: '0'
                    },
                    '&.done': {
                      backgroundColor: 'primary.400'
                    }
                  },
                }"
                py="30px"
                justify-content="space-between"
                align-items="center"
              >
                <c-box class="step done" />
                <c-box class="step" />
                <c-box class="step" />
                <c-box class="step" />
                <c-box class="step" />
                <c-box class="step" />
                <c-box class="step" />
              </c-flex>
              <c-box>
                <c-heading
                  font-weight="600"
                  font-size="18px"
                  line-height="27px"
                  color="black.900"
                  margin-bottom="30px"
                >
                  Identitas
                </c-heading>

                <c-box margin-bottom="30px">
                  <c-text
                    color="primary.400"
                    font-family="roboto"
                    font-weight="500"
                    font-size="16px"
                    margin-bottom="8px"
                  >
                    Nama Depan
                  </c-text>
                  <c-text
                    color="superDarkGray.900"
                    font-family="roboto"
                    font-weight="400"
                    font-size="18px"
                  >
                    Jennie
                  </c-text>
                </c-box>
                <c-box margin-bottom="30px">
                  <c-text
                    color="primary.400"
                    font-family="roboto"
                    font-weight="500"
                    font-size="16px"
                    margin-bottom="8px"
                  >
                    Nama Belakang
                  </c-text>
                  <c-text
                    color="superDarkGray.900"
                    font-family="roboto"
                    font-weight="400"
                    font-size="18px"
                  >
                    Kim
                  </c-text>
                </c-box>
                <c-box margin-bottom="30px">
                  <c-text
                    color="primary.400"
                    font-family="roboto"
                    font-weight="500"
                    font-size="16px"
                    margin-bottom="8px"
                  >
                    Nama Tangah
                  </c-text>
                  <c-text
                    color="superDarkGray.900"
                    font-family="roboto"
                    font-weight="400"
                    font-size="18px"
                  >
                    Ruby
                  </c-text>
                </c-box>
                <c-box margin-bottom="30px">
                  <c-text
                    color="primary.400"
                    font-family="roboto"
                    font-weight="500"
                    font-size="16px"
                    margin-bottom="8px"
                  >
                    Jenis Kelamin
                  </c-text>
                  <c-text
                    color="superDarkGray.900"
                    font-family="roboto"
                    font-weight="400"
                    font-size="18px"
                  >
                    Perempuan
                  </c-text>
                </c-box>
                <c-box margin-bottom="30px">
                  <c-text
                    color="primary.400"
                    font-family="roboto"
                    font-weight="500"
                    font-size="16px"
                    margin-bottom="8px"
                  >
                    Usia
                  </c-text>
                  <c-text
                    color="superDarkGray.900"
                    font-family="roboto"
                    font-weight="400"
                    font-size="18px"
                  >
                    25 tahun
                  </c-text>
                </c-box>
                <c-box margin-bottom="30px">
                  <c-text
                    color="primary.400"
                    font-family="roboto"
                    font-weight="500"
                    font-size="16px"
                    margin-bottom="8px"
                  >
                    Tanggal Lahir
                  </c-text>
                  <c-text
                    color="superDarkGray.900"
                    font-family="roboto"
                    font-weight="400"
                    font-size="18px"
                  >
                    25 January 1996
                  </c-text>
                </c-box>
                <c-box margin-bottom="30px">
                  <c-text
                    color="primary.400"
                    font-family="roboto"
                    font-weight="500"
                    font-size="16px"
                    margin-bottom="8px"
                  >
                    Alamat
                  </c-text>
                  <c-text
                    color="superDarkGray.900"
                    font-family="roboto"
                    font-weight="400"
                    font-size="18px"
                  >
                    Jalan Sukun Gang Matahari No 2 Karangbendo Banguntapan Bantul
                  </c-text>
                </c-box>
                <c-box margin-bottom="30px">
                  <c-text
                    color="primary.400"
                    font-family="roboto"
                    font-weight="500"
                    font-size="16px"
                    margin-bottom="8px"
                  >
                    Negara
                  </c-text>
                  <c-text
                    color="superDarkGray.900"
                    font-family="roboto"
                    font-weight="400"
                    font-size="18px"
                  >
                    Indonesia
                  </c-text>
                </c-box>
                <c-box margin-bottom="30px">
                  <c-text
                    color="primary.400"
                    font-family="roboto"
                    font-weight="500"
                    font-size="16px"
                    margin-bottom="8px"
                  >
                    Provinsi
                  </c-text>
                  <c-text
                    color="superDarkGray.900"
                    font-family="roboto"
                    font-weight="400"
                    font-size="18px"
                  >
                    D.I. Yogyakarta
                  </c-text>
                </c-box>
                <c-box margin-bottom="30px">
                  <c-text
                    color="primary.400"
                    font-family="roboto"
                    font-weight="500"
                    font-size="16px"
                    margin-bottom="8px"
                  >
                    Kota
                  </c-text>
                  <c-text
                    color="superDarkGray.900"
                    font-family="roboto"
                    font-weight="400"
                    font-size="18px"
                  >
                    Slemen
                  </c-text>
                </c-box>
                <c-box margin-bottom="30px">
                  <c-text
                    color="primary.400"
                    font-family="roboto"
                    font-weight="500"
                    font-size="16px"
                    margin-bottom="8px"
                  >
                    Kode Pos
                  </c-text>
                  <c-text
                    color="superDarkGray.900"
                    font-family="roboto"
                    font-weight="400"
                    font-size="18px"
                  >
                    63371
                  </c-text>
                </c-box>
                <c-box margin-bottom="30px">
                  <c-text
                    color="primary.400"
                    font-family="roboto"
                    font-weight="500"
                    font-size="16px"
                    margin-bottom="8px"
                  >
                    Nomor Telepon
                  </c-text>
                  <c-text
                    color="superDarkGray.900"
                    font-family="roboto"
                    font-weight="400"
                    font-size="18px"
                  >
                    0812 3456 7890
                  </c-text>
                </c-box>
                <c-box margin-bottom="30px">
                  <c-text
                    color="primary.400"
                    font-family="roboto"
                    font-weight="500"
                    font-size="16px"
                    margin-bottom="8px"
                  >
                    Apa tujuan utama kamu?
                  </c-text>
                  <c-text
                    color="superDarkGray.900"
                    font-family="roboto"
                    font-weight="400"
                    font-size="18px"
                  >
                    Pilih tujuan utama kamu
                  </c-text>
                </c-box>
                <c-box margin-bottom="30px">
                  <c-text
                    color="primary.400"
                    font-family="roboto"
                    font-weight="500"
                    font-size="16px"
                    margin-bottom="8px"
                  >
                    Tipikal diet yang kamu harapkan
                  </c-text>
                  <c-text
                    color="superDarkGray.900"
                    font-family="roboto"
                    font-weight="400"
                    font-size="18px"
                  >
                    Tipikal diet yang kamu harapkan
                  </c-text>
                </c-box>
                <c-box margin-bottom="30px">
                  <c-text
                    color="primary.400"
                    font-family="roboto"
                    font-weight="500"
                    font-size="16px"
                    margin-bottom="8px"
                  >
                    Apakah kamu memiliki timbangan berat badan di rumah?
                  </c-text>
                  <c-text
                    color="superDarkGray.900"
                    font-family="roboto"
                    font-weight="400"
                    font-size="18px"
                  >
                    Apakah kamu memiliki timbangan berat badan di rumah?
                  </c-text>
                </c-box>
                <c-box margin-bottom="30px">
                  <c-text
                    color="primary.400"
                    font-family="roboto"
                    font-weight="500"
                    font-size="16px"
                    margin-bottom="8px"
                  >
                    Mana yang paling mendeskripsikan kondisi Klien saat ini?
                  </c-text>
                  <c-text
                    color="superDarkGray.900"
                    font-family="roboto"
                    font-weight="400"
                    font-size="18px"
                  >
                    Mana yang paling mendeskripsikan kondisi Klien saat ini?
                  </c-text>
                </c-box>
              </c-box>
              <c-flex
                justify-content="space-between"
                align-items="center"
                margin-top="30px"
              >
                <c-button
                  left-icon="chevron-left"
                  background-color="primary.400"
                  color="white"
                  border-radius="100px"
                  variant="solid"
                  px="30px"
                >
                  Kembali
                </c-button>
                <c-button
                  right-icon="chevron-right"
                  background-color="primary.400"
                  color="white"
                  border-radius="100px"
                  variant="solid"
                  px="30px"
                >
                  Selanjutnya
                </c-button>
              </c-flex>
            </c-box>
          </c-flex>
        </c-box>
      </c-box>
    </c-box>
    <FooterComp />
  </c-box>
</template>

<script>
import HeaderComp from '@/components/header/index.vue'
import FooterComp from '@/components/layouts/footer/index.vue'

export default {
  name: 'DetailClient',
  components: {
    HeaderComp,
    FooterComp,
  },
}
</script>
