var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "id": "listNutritionists"
    }
  }, [_c('HeaderComp'), _c('c-box', {
    attrs: {
      "padding-top": "30px",
      "padding-bottom": "80px"
    }
  }, [_c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": "1200px",
      "mx": "auto"
    }
  }, [_c('c-breadcrumb', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        'ol': {
          'li': {
            'a': {
              fontSize: '14px',
              fontFamily: 'Roboto'
            },
            'span': {
              fontSize: '14px',
              fontFamily: 'Roboto'
            }
          }
        }
      },
      expression: "{\n          'ol': {\n            'li': {\n              'a': {\n                fontSize: '14px',\n                fontFamily: 'Roboto'\n              },\n              'span': {\n                fontSize: '14px',\n                fontFamily: 'Roboto',\n              },\n            },\n          },\n\n        }"
    }],
    attrs: {
      "margin-top": "20px",
      "margin-bottom": "20px"
    }
  }, [_c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "href": "#",
      "color": "primary.400",
      "font-size": "14px",
      "font-fammily": "Roboto"
    }
  }, [_vm._v(" Beranda ")])], 1), _c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "href": "#",
      "color": "primary.400",
      "font-size": "14px",
      "font-fammily": "Roboto"
    }
  }, [_vm._v(" Manajemen Klien ")])], 1), _c('c-breadcrumb-item', {
    attrs: {
      "is-current-page": ""
    }
  }, [_c('c-breadcrumb-link', {
    attrs: {
      "href": "#",
      "font-size": "14px !important",
      "font-fammily": "Roboto !important"
    }
  }, [_vm._v(" Diet Data Klien ")])], 1)], 1), _c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": "1200px",
      "mx": "auto",
      "margin-top": "50px"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-wrap": "wrap",
      "w": "100%"
    }
  }, [_c('c-box', {
    attrs: {
      "w": "40%",
      "padding-right": "20px"
    }
  }, [_c('c-flex', {
    attrs: {
      "w": "100%",
      "px": "0",
      "py": "0"
    }
  }, [_c('c-box', {
    attrs: {
      "width": "72px",
      "height": "72px",
      "margin-right": "30px"
    }
  }, [_c('c-box', {
    attrs: {
      "w": "72px",
      "h": "72px",
      "overflow": "hidden",
      "border-radius": "100%",
      "background-color": "lightGray.900"
    }
  })], 1), _c('c-box', {
    attrs: {
      "w": "100%"
    }
  }, [_c('c-heading', {
    attrs: {
      "as": "h3",
      "pos": "relative",
      "margin-bottom": "10px",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "700",
      "color": "black.900"
    }
  }, [_vm._v(" Nama Klien ")]), _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": "14px",
      "color": "primary.400",
      "margin-bottom": "20px"
    }
  }, [_vm._v(" 98782629109 ")])], 1)], 1), _c('c-list', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        'li': {
          'a': {
            color: 'darkGray.900',
            fontSize: '16px',
            lineHeight: '24px',
            padding: '15px 20px',
            display: 'block',
            fontWeight: '700',
            borderRadius: '8px',
            '&:hover': {
              textDecoration: 'none',
              backgroundColor: 'superLightGray.900'
            },
            '&:focus': {
              outline: 'none',
              boxShadow: 'none'
            }
          },
          '&.active': {
            'a': {
              backgroundColor: 'superLightGray.900',
              borderLeft: '6px solid #008C81',
              'img': {
                'path': {
                  fill: '#008C81 !important'
                }
              }
            }
          }
        }
      },
      expression: "{\n                'li': {\n                  'a': {\n                    color: 'darkGray.900',\n                    fontSize: '16px',\n                    lineHeight: '24px',\n                    padding: '15px 20px',\n                    display: 'block',\n                    fontWeight: '700',\n                    borderRadius: '8px',\n                    '&:hover': {\n                      textDecoration: 'none',\n                      backgroundColor: 'superLightGray.900'\n                    },\n                    '&:focus': {\n                      outline: 'none',\n                      boxShadow: 'none'\n                    }\n                  },\n                  '&.active': {\n                    'a': {\n                      backgroundColor: 'superLightGray.900',\n                      borderLeft: '6px solid #008C81',\n                      'img': {\n                        'path': {\n                          fill: '#008C81 !important'\n                        }\n                      }\n                    }\n                  }\n                }\n              }"
    }]
  }, [_c('c-list-item', {
    staticClass: "active",
    attrs: {
      "margin-top": "30px"
    }
  }, [_c('c-link', {
    attrs: {
      "href": "#"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic_user.svg'),
      "alt": "icon",
      "display": "inline-block",
      "vertical-align": "middle",
      "margin-right": "8px"
    }
  }), _vm._v(" Data Diet Klien ")], 1)], 1), _c('c-list-item', {
    attrs: {
      "margin-top": "30px"
    }
  }, [_c('c-link', {
    attrs: {
      "href": "#"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic_notebook.svg'),
      "alt": "icon",
      "display": "inline-block",
      "vertical-align": "middle",
      "margin-right": "8px"
    }
  }), _vm._v(" ADIME Note ")], 1)], 1), _c('c-list-item', {
    attrs: {
      "margin-top": "30px"
    }
  }, [_c('c-link', {
    attrs: {
      "href": "#"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic_notes.svg'),
      "alt": "icon",
      "display": "inline-block",
      "vertical-align": "middle",
      "margin-right": "8px"
    }
  }), _vm._v(" Follow Up Notes ")], 1)], 1), _c('c-list-item', {
    attrs: {
      "margin-top": "30px"
    }
  }, [_c('c-link', {
    attrs: {
      "href": "#"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic_trending.svg'),
      "alt": "icon",
      "display": "inline-block",
      "vertical-align": "middle",
      "margin-right": "8px"
    }
  }), _vm._v(" Progress & Tracker ")], 1)], 1), _c('c-list-item', {
    attrs: {
      "margin-top": "30px"
    }
  }, [_c('c-link', {
    attrs: {
      "href": "#"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic_meat.svg'),
      "alt": "icon",
      "display": "inline-block",
      "vertical-align": "middle",
      "margin-right": "8px"
    }
  }), _vm._v(" Meal Planner ")], 1)], 1)], 1)], 1), _c('c-box', {
    attrs: {
      "w": "60%"
    }
  }, [_c('c-box', {
    attrs: {
      "margin-bottom": "40px"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-family": "roboto",
      "text-transform": "uppercase",
      "font-weight": "700",
      "font-size": "18px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(" Formulir Gizi ")]), _c('c-heading', {
    attrs: {
      "font-weight": "600",
      "font-size": "24px",
      "line-height": "36px",
      "color": "black.900"
    }
  }, [_vm._v(" Program Body Goals ")])], 1), _c('c-flex', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '.step': {
          w: '100%',
          h: '8px',
          maxWidth: '156px',
          borderRadius: '20px',
          backgroundColor: 'superLightGray.900',
          marginRight: '12px',
          ':last-child': {
            marginLeft: '0'
          },
          '&.done': {
            backgroundColor: 'primary.400'
          }
        }
      },
      expression: "{\n                '.step': {\n                  w: '100%',\n                  h: '8px',\n                  maxWidth: '156px',\n                  borderRadius: '20px',\n                  backgroundColor: 'superLightGray.900',\n                  marginRight: '12px',\n                  ':last-child': {\n                    marginLeft: '0'\n                  },\n                  '&.done': {\n                    backgroundColor: 'primary.400'\n                  }\n                },\n              }"
    }],
    attrs: {
      "py": "30px",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c('c-box', {
    staticClass: "step done"
  }), _c('c-box', {
    staticClass: "step"
  }), _c('c-box', {
    staticClass: "step"
  }), _c('c-box', {
    staticClass: "step"
  }), _c('c-box', {
    staticClass: "step"
  }), _c('c-box', {
    staticClass: "step"
  }), _c('c-box', {
    staticClass: "step"
  })], 1), _c('c-box', [_c('c-heading', {
    attrs: {
      "font-weight": "600",
      "font-size": "18px",
      "line-height": "27px",
      "color": "black.900",
      "margin-bottom": "30px"
    }
  }, [_vm._v(" Identitas ")]), _c('c-box', {
    attrs: {
      "margin-bottom": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-family": "roboto",
      "font-weight": "500",
      "font-size": "16px",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" Nama Depan ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-family": "roboto",
      "font-weight": "400",
      "font-size": "18px"
    }
  }, [_vm._v(" Jennie ")])], 1), _c('c-box', {
    attrs: {
      "margin-bottom": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-family": "roboto",
      "font-weight": "500",
      "font-size": "16px",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" Nama Belakang ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-family": "roboto",
      "font-weight": "400",
      "font-size": "18px"
    }
  }, [_vm._v(" Kim ")])], 1), _c('c-box', {
    attrs: {
      "margin-bottom": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-family": "roboto",
      "font-weight": "500",
      "font-size": "16px",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" Nama Tangah ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-family": "roboto",
      "font-weight": "400",
      "font-size": "18px"
    }
  }, [_vm._v(" Ruby ")])], 1), _c('c-box', {
    attrs: {
      "margin-bottom": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-family": "roboto",
      "font-weight": "500",
      "font-size": "16px",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" Jenis Kelamin ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-family": "roboto",
      "font-weight": "400",
      "font-size": "18px"
    }
  }, [_vm._v(" Perempuan ")])], 1), _c('c-box', {
    attrs: {
      "margin-bottom": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-family": "roboto",
      "font-weight": "500",
      "font-size": "16px",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" Usia ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-family": "roboto",
      "font-weight": "400",
      "font-size": "18px"
    }
  }, [_vm._v(" 25 tahun ")])], 1), _c('c-box', {
    attrs: {
      "margin-bottom": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-family": "roboto",
      "font-weight": "500",
      "font-size": "16px",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" Tanggal Lahir ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-family": "roboto",
      "font-weight": "400",
      "font-size": "18px"
    }
  }, [_vm._v(" 25 January 1996 ")])], 1), _c('c-box', {
    attrs: {
      "margin-bottom": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-family": "roboto",
      "font-weight": "500",
      "font-size": "16px",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" Alamat ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-family": "roboto",
      "font-weight": "400",
      "font-size": "18px"
    }
  }, [_vm._v(" Jalan Sukun Gang Matahari No 2 Karangbendo Banguntapan Bantul ")])], 1), _c('c-box', {
    attrs: {
      "margin-bottom": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-family": "roboto",
      "font-weight": "500",
      "font-size": "16px",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" Negara ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-family": "roboto",
      "font-weight": "400",
      "font-size": "18px"
    }
  }, [_vm._v(" Indonesia ")])], 1), _c('c-box', {
    attrs: {
      "margin-bottom": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-family": "roboto",
      "font-weight": "500",
      "font-size": "16px",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" Provinsi ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-family": "roboto",
      "font-weight": "400",
      "font-size": "18px"
    }
  }, [_vm._v(" D.I. Yogyakarta ")])], 1), _c('c-box', {
    attrs: {
      "margin-bottom": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-family": "roboto",
      "font-weight": "500",
      "font-size": "16px",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" Kota ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-family": "roboto",
      "font-weight": "400",
      "font-size": "18px"
    }
  }, [_vm._v(" Slemen ")])], 1), _c('c-box', {
    attrs: {
      "margin-bottom": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-family": "roboto",
      "font-weight": "500",
      "font-size": "16px",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" Kode Pos ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-family": "roboto",
      "font-weight": "400",
      "font-size": "18px"
    }
  }, [_vm._v(" 63371 ")])], 1), _c('c-box', {
    attrs: {
      "margin-bottom": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-family": "roboto",
      "font-weight": "500",
      "font-size": "16px",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" Nomor Telepon ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-family": "roboto",
      "font-weight": "400",
      "font-size": "18px"
    }
  }, [_vm._v(" 0812 3456 7890 ")])], 1), _c('c-box', {
    attrs: {
      "margin-bottom": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-family": "roboto",
      "font-weight": "500",
      "font-size": "16px",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" Apa tujuan utama kamu? ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-family": "roboto",
      "font-weight": "400",
      "font-size": "18px"
    }
  }, [_vm._v(" Pilih tujuan utama kamu ")])], 1), _c('c-box', {
    attrs: {
      "margin-bottom": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-family": "roboto",
      "font-weight": "500",
      "font-size": "16px",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" Tipikal diet yang kamu harapkan ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-family": "roboto",
      "font-weight": "400",
      "font-size": "18px"
    }
  }, [_vm._v(" Tipikal diet yang kamu harapkan ")])], 1), _c('c-box', {
    attrs: {
      "margin-bottom": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-family": "roboto",
      "font-weight": "500",
      "font-size": "16px",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" Apakah kamu memiliki timbangan berat badan di rumah? ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-family": "roboto",
      "font-weight": "400",
      "font-size": "18px"
    }
  }, [_vm._v(" Apakah kamu memiliki timbangan berat badan di rumah? ")])], 1), _c('c-box', {
    attrs: {
      "margin-bottom": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-family": "roboto",
      "font-weight": "500",
      "font-size": "16px",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" Mana yang paling mendeskripsikan kondisi Klien saat ini? ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-family": "roboto",
      "font-weight": "400",
      "font-size": "18px"
    }
  }, [_vm._v(" Mana yang paling mendeskripsikan kondisi Klien saat ini? ")])], 1)], 1), _c('c-flex', {
    attrs: {
      "justify-content": "space-between",
      "align-items": "center",
      "margin-top": "30px"
    }
  }, [_c('c-button', {
    attrs: {
      "left-icon": "chevron-left",
      "background-color": "primary.400",
      "color": "white",
      "border-radius": "100px",
      "variant": "solid",
      "px": "30px"
    }
  }, [_vm._v(" Kembali ")]), _c('c-button', {
    attrs: {
      "right-icon": "chevron-right",
      "background-color": "primary.400",
      "color": "white",
      "border-radius": "100px",
      "variant": "solid",
      "px": "30px"
    }
  }, [_vm._v(" Selanjutnya ")])], 1)], 1)], 1)], 1)], 1)], 1), _c('FooterComp')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }